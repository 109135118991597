/**
 * Lightboxed form BLOCKS (but not the lightboxed forms for products and
 * checkout)
 *
 * @module squarespace-form-rendering-utils
 */

import { t } from 'shared/i18n';
import { GoogleReCaptchaAPI } from '@sqs/universal-utils';
import renderCharacterCounters from 'shared/components/forms/CharacterCounter';

YUI.add('squarespace-form-rendering-utils', function (Y) {

  Y.namespace('Squarespace');

  /**
   * Copy the form for the lightbox usage
   *
   * @param {YUI.Node} formWrapper The existing form wrapper on the page to copy from.
   * @return {YUI.Node}         A copied node ready for usage in the lightbox
   */
  function copyFormForLightbox(formWrapper) {
    var clonedForm = formWrapper.cloneNode(true);

    var buttonNode = clonedForm.one('.form-button-wrapper .sqs-system-button');
    if (buttonNode) {
      buttonNode.removeClass('sqs-editable-button');
    }

    var captchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(
    Y.config.win,
    clonedForm.getDOMNode());

    if (captchaContainer) {
      GoogleReCaptchaAPI.destroy(captchaContainer);
    }

    return clonedForm;
  }

  /**
   * @method renderBlockWithLightbox
   * @param blockNode
   */
  function renderBlockWithLightbox(blockNode) {
    // For such cases where blocks are being re-initialized,
    // e.g. templates where portions are loaded asynchronously
    if (!blockNode.one('.form-wrapper')) {
      return;
    }

    var formWrapper = blockNode.one('.form-wrapper').remove().removeClass('hidden');
    var lightboxHandle = blockNode.one('.lightbox-handle');
    var existingLightboxWidget = lightboxHandle.getData('lightbox');

    if (!existingLightboxWidget) {
      var clonedFormWrapper = copyFormForLightbox(formWrapper);
      renderCharacterCounters(clonedFormWrapper.getDOMNode());

      var lightbox = new Y.Squarespace.Widgets.ModalLightbox({
        content: clonedFormWrapper,
        title: t("Form"),



        render: Y.one('body') });


      var initialCaptchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(
      Y.config.win,
      clonedFormWrapper.getDOMNode());

      if (initialCaptchaContainer) {
        GoogleReCaptchaAPI.render(Y.config.win, initialCaptchaContainer);
      }

      // reset the lightbox content when it closes
      lightbox.on('close', function resetOnClose() {
        clonedFormWrapper = copyFormForLightbox(formWrapper);
        var formWrapperEl = clonedFormWrapper.getDOMNode();
        renderCharacterCounters(formWrapperEl);
        lightbox.set('content', clonedFormWrapper);

        var captchaContainer = GoogleReCaptchaAPI.getCaptchaContainer(
        Y.config.win,
        formWrapperEl);

        if (captchaContainer) {
          GoogleReCaptchaAPI.render(Y.config.win, captchaContainer);
        }
      }, this);

      lightboxHandle.setData('lightbox', lightbox);
    }

    lightboxHandle.detach('click');
    lightboxHandle.on('click', function (e) {
      e.halt();
      lightbox.open();
    }, this);
  }

  /**
   * Utilitiy functions related to the rendering forms.
   *
   * @namespace Squarespace
   * @class FormRenderingUtils
   * @static
   */
  Y.Squarespace.FormRenderingUtils = {
    initializeFormLightbox: function (root) {
      root.all('.sqs-block-form').each(function (formBlockNode) {
        if (!formBlockNode.one('.lightbox-handle')) {
          return;
        }
        renderBlockWithLightbox(formBlockNode);
      });
    } };



  Y.config.win.Squarespace.onInitialize(Y, function () {
    var doc = Y.config.doc;
    var body = Y.one(doc.body);
    Y.Squarespace.FormRenderingUtils.initializeFormLightbox(body);
  });

}, '1.0', {
  requires: [
  'squarespace-modal-lightbox'] });